import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import "./common.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomAccordion from './CustomAccordion';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { INTERNAL_API_URL } from './config';

const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
const toMMddYY = date => {
    if (date === null) return "";
    if (date.getFullYear() < 2000) return "";
    return pad(date.getMonth() + 1) +
        "/" + pad(date.getDate()) +
        "/" + pad(date.getFullYear() % 100);
};

const ScandataReviewColumns = [

    {
        dataField: 'scandata_group_name', sort: true, text: 'Scandata Group',
        //filter: textFilter({ filterValue: this.state.filterValue, onFilter: (filterValue) => { console.log(filterValue) } }),
        headerStyle: (column, colIndex) => { return { width: '210px' }; },
        style: (cell, row, rowIndex, colIndex) => {
            return { width: '200px', wordWrap: "break-word", overflowWrap: "break-word" };
        }
    },
    {
        dataField: 'altria_status', sort: true, text: 'Altria Status', filter: textFilter({ defaultValue: '' }), headerStyle: (column, colIndex) => { return { width: '250px' }; }
    },
    {
        dataField: 'altria_wed', sort: false, text: 'Week Ending', formatter: (cellContent, row) => {
            var dt = new Date(row.altria_wed);
            var min = dt.getTimezoneOffset();
            var ndt = new Date(dt.getTime() + min * 60000);
            var fmtTime = toMMddYY(ndt);
            return (<span>{fmtTime}</span>)
        },
        headerStyle: (colum, colIndex) => { return { width: '140px' }; },
        //headerFormatter: (column, columnIndex) => {return <div onClick="test">Week Ending<NavigateNextIcon></NavigateNextIcon></div> }

    },
    {
        dataField: 'rjr_status', sort: true, text: 'RJR Status', filter: textFilter({ defaultValue: '' }), headerStyle: (column, colIndex) => { return { width: '250px' }; }
    },
    {
        dataField: 'rjr_wed', sort: true, text: 'Week Ending', formatter: (cellContent, row) => {
            var dt = new Date(row.rjr_wed);
            var min = dt.getTimezoneOffset();
            var ndt = new Date(dt.getTime() + min * 60000);
            var fmtTime = toMMddYY(ndt);
            return (<span>{fmtTime}</span>)
        },
        headerStyle: (colum, colIndex) => { return { width: '140px' }; }

    },
    {
        dataField: 'rjr_circana_status', sort: true, text: 'RJR Circana Status', filter: textFilter({ defaultValue: '' }), headerStyle: (column, colIndex) => { return { width: '250px' }; }
    },
    {
        dataField: 'rjr_circana_wed', sort: true, text: 'Week Ending', formatter: (cellContent, row) => {
            var dt = new Date(row.rjr_circana_wed);
            var min = dt.getTimezoneOffset();
            var ndt = new Date(dt.getTime() + min * 60000);
            var fmtTime = toMMddYY(ndt);
            return (<span>{fmtTime}</span>)
        },
        headerStyle: (colum, colIndex) => { return { width: '140px' }; }

    },
    {
        dataField: 'altria_activity_status', sort: true, text: 'Activity Status', filter: textFilter({ defaultValue: '' }), headerStyle: (column, colIndex) => { return { width: '250px' }; }
    },
    {
        dataField: 'altria_activity_wed', sort: true, text: 'Week Ending', formatter: (cellContent, row) => {
            var dt = new Date(row.altria_activity_wed);
            var min = dt.getTimezoneOffset();
            var ndt = new Date(dt.getTime() + min * 60000);
            var fmtTime = toMMddYY(ndt);
            return (<span>{fmtTime}</span>)
        },
        headerStyle: (colum, colIndex) => { return { width: '140px' }; }

    },
    {
        dataField: 'altria_benchmark_status', sort: true, text: 'Benchmark Status', filter: textFilter({ defaultValue: '' }), headerStyle: (column, colIndex) => { return { width: '250px' }; }
    },
    {
        dataField: 'altria_benchmark_wed', sort: true, text: 'Week Ending', formatter: (cellContent, row) => {
            var dt = new Date(row.altria_benchmark_wed);
            var min = dt.getTimezoneOffset();
            var ndt = new Date(dt.getTime() + min * 60000);
            var fmtTime = toMMddYY(ndt);
            return (<span>{fmtTime}</span>)
        },
        headerStyle: (colum, colIndex) => { return { width: '140px' }; }

    }
];


const selectRow = {
    mode: 'radio',
    style: { color: 'red' },
    clickToSelect: true,

};


export class ScandataReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            reviews: null,
            selectedReportingGroup: null,
            reviewsLoading: false,
            AltriaLogFileContent: null,
            RJRCircanaLogFileContent: null,
            RJRLogFileContent: null,
            ActivityLogFileContent: null,
            BenchmarkLogFileContent: null,
            blurDisplay: "block",
            periodToAdd: 0,
            showCalendar: false,
            selectedDate: null,

        }
        this.filter = null;
        this.filterValue = '';
        this.getReviewData = this.getReviewData.bind(this);
        this.handleNextWeekEnding = this.handleNextWeekEnding.bind(this);
        this.handlePreviousWeekEnding = this.handlePreviousWeekEnding.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleGroupSelect = this.handleGroupSelect.bind(this);

    }



    componentDidMount() {
        console.log("componentDidMount in ScandataReview");
        if (this.state.reviewsLoading === false) {
            this.setState({ reviewsLoading: true });
            this.getReviewData();
            const now = new Date()
            const dayOfWeekNow = now.getDay()
            const currentWeekEnding = new Date(now.getTime() - dayOfWeekNow * 24 * 3600 * 1000)
            this.setState({ selectedDate: dayjs(currentWeekEnding) })

        }
    }
    componentDidUpdate() {
        if (this.filter)
            this.filter(this.filterValue)
    }

    handlePreviousWeekEnding() {
        var periods = this.state.periodToAdd;
        var tempSelectedDate = new Date(this.state.selectedDate);
        this.setState({ reviews: null, reviewsLoading: false, periodToAdd: periods - 1, selectedDate: dayjs(new Date(tempSelectedDate.getTime() - 7 * 24 * 3600 * 1000)), selectedReportingGroup: null }, () => {
            this.getReviewData();
        });
       
    }

    disableDate(date) {
        const now = new Date()
        const dayOfWeekNow = now.getDay()
        const currentWeekEnding = new Date(now.getTime() - dayOfWeekNow * 24 * 3600 * 1000)
        const dDate = new Date(date)
        return (dDate.getDay() !== 0 && dDate.getDay() !== 6) || (new Date(date)) > currentWeekEnding
    }

    handleNextWeekEnding() {
        var periods = this.state.periodToAdd;
        var tempSelectedDate = new Date(this.state.selectedDate);
        if (periods < 0) {
            this.setState({ reviews: null, periodToAdd: periods + 1, selectedDate: dayjs(new Date(tempSelectedDate.getTime() + 7 * 24 * 3600 * 1000)), selectedReportingGroup: null }, () => {
                this.getReviewData();
            });

        }
    }

    handleButtonClick() {
        this.setState({ reviews: null, reviewsLoading: false, periodToAdd: 0, selectedReportingGroup: null }, () => {
            this.getReviewData();
        });

    }
    handleGroupSelect = (e, childData) => {
        this.setState({ selectedReportingGroup: childData });
        var eObj = e.target;
        while (eObj.nodeName !== "TD") {
            if (eObj.parentNode === null) return;
            eObj = eObj.parentNode;
        }

        var outputFileName = "";
        switch (eObj.cellIndex) {
            case 1:
            case 2:
                if (childData.altria !== null) {
                    outputFileName = childData.altria.output_filename;
                }
                break;
            case 3:
            case 4:
                if (childData.rjr !== null) {
                    outputFileName = childData.rjr.output_filename;
                }
                break;
            case 5:
            case 6:
                if (childData.rjr_circana !== null) {
                    outputFileName = childData.rjr_circana.output_filename;
                }
                break;
            case 7:
            case 8:
                if (childData.altria_activity !== null) {
                    outputFileName = childData.altria_activity.output_filename;
                }
                break;
            case 9:
            case 10:
                if (childData.altria_benchmark !== null) {
                    outputFileName = childData.altria_benchmark.output_filename;
                }
                break;
            default:
                break;
        }
        this.setState({
            reviewLoading: true, AltriaLogFileContent: null, RJRLogFileContent: null, RJRCircanaLogFileContent: null, BenchmarkLogFileContent: null, ActivityLogFileContent: null, blurDisplay: "block"
        });
        let altriaLogFileRequest = null;
        let rjrLogFileRequest = null;
        let rjrCircanaLogFileRequest = null;
        let benchmarkFileRequest = null;
        let activityFileRequest = null;
        let bodyText = null;
        let url = null;

        if (childData.rjr_circana !== null) {
            bodyText = JSON.stringify({
                ScandataGroupName: childData.scandata_group_name,
                RunLogFileName: childData.rjr_circana.run_log_filename,
                OpRunLogFileName: childData.rjr_circana.op_run_log_filename,
                SummaryLogFileName: childData.rjr_circana.summary_log_filename
            });
            let requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: bodyText
            };
            url = INTERNAL_API_URL + '/Scandata/GetScandataLogFile';
            rjrCircanaLogFileRequest = fetch(url, requestOptions)
                .then((response) => {
                    //this.setState({ reviewLoading: false, blurDisplay: "none" });
                    return response.json();
                });

        }
        if (childData.rjr !== null) {
            bodyText = JSON.stringify({
                ScandataGroupName: childData.scandata_group_name,
                RunLogFileName: childData.rjr.run_log_filename,
                OpRunLogFileName: childData.rjr.op_run_log_filename,
                SummaryLogFileName: childData.rjr.summary_log_filename
            });
            let requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: bodyText
            };
            url = INTERNAL_API_URL + '/Scandata/GetScandataLogFile';
            rjrLogFileRequest = fetch(url, requestOptions)
                .then((response) => {
                    //this.setState({ reviewLoading: false, blurDisplay: "none" });
                    return response.json();
                });

        }

        if (childData.altria !== null) {
            bodyText = JSON.stringify({
                ScandataGroupName: childData.scandata_group_name,
                RunLogFileName: childData.altria.run_log_filename,
                OpRunLogFileName: childData.altria.op_run_log_filename,
                SummaryLogFileName: childData.altria.summary_log_filename
            });
            let requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: bodyText
            };
            url = INTERNAL_API_URL + '/Scandata/GetScandataLogFile';
            altriaLogFileRequest = fetch(url, requestOptions)
                .then((response) => {
                    //this.setState({ reviewLoading: false, blurDisplay: "none" });
                    return response.json();
                });

        }
        if (childData.altria_benchmark !== null) {
            bodyText = JSON.stringify({
                ScandataGroupName: childData.scandata_group_name,
                RunLogFileName: childData.altria_benchmark.run_log_filename,
                OpRunLogFileName: childData.altria_benchmark.op_run_log_filename,
                SummaryLogFileName: childData.altria_benchmark.summary_log_filename
            });
            let requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: bodyText
            };
            url = INTERNAL_API_URL + '/Scandata/GetScandataLogFile';
            benchmarkFileRequest = fetch(url, requestOptions)
                .then((response) => {
                    //this.setState({ reviewLoading: false, blurDisplay: "none" });
                    return response.json();
                });

        }

        if (childData.altria_activity !== null) {
            bodyText = JSON.stringify({
                ScandataGroupName: childData.scandata_group_name,
                RunLogFileName: childData.altria_activity.run_log_filename,
                OpRunLogFileName: childData.altria_activity.op_run_log_filename,
                SummaryLogFileName: childData.altria_activity.summary_log_filename
            });
            let requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: bodyText
            };
            url = INTERNAL_API_URL + '/Scandata/GetScandataLogFile';
            activityFileRequest = fetch(url, requestOptions)
                .then((response) => {
                    //this.setState({ reviewLoading: false, blurDisplay: "none" });
                    return response.json();
                });

        }
        Promise.all([altriaLogFileRequest, rjrLogFileRequest, rjrCircanaLogFileRequest, benchmarkFileRequest, activityFileRequest])
            .then(([altriaData, rjrData, rjrCircanaData, altriaBenchmarkData, altriaActivityData]) => {
                this.setState({ reviewLoading: false, blurDisplay: "none", AltriaLogFileContent: altriaData, BenchmarkLogFileContent: altriaBenchmarkData, RJRCircanaLogFileContent: rjrCircanaData, RJRLogFileContent: rjrData, ActivityLogFileContent: altriaActivityData })

            })
            .catch(error => {
                console.error(error);
            });
        console.log(childData);
        console.log(childData.scandata_group_name + " " + outputFileName);
    }


    getReviewData() {
        this.setState({
            reviewLoading: true, reviews: null, blurDisplay: "block"
        });
        var url = INTERNAL_API_URL + '/Scandata/GetScandataReview';
        let bodyText = JSON.stringify({
            PeriodToAdd: this.state.periodToAdd
        }
        );
        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodyText
        })
            .then((response) => {
                this.setState({ reviewLoading: false, blurDisplay: "none" });

                if (!response.ok) {
                    var messageText = "Error reading GetScandataReview: " + JSON.stringify(response);
                    confirmAlert({
                        title: 'Error Retrieving Scandata Review',
                        message: messageText,
                        buttons: [
                            {
                                label: 'Try Again',
                                onClick: () => this.getReviewData()
                            },
                            {
                                label: 'Continue',
                            }

                        ]
                    });
                }
                else return response.json();
            })
            .then((dobj) => {
                //var objCount = Object.keys(dobj).length;
                console.log(dobj);
                this.setState({ reviews: dobj });
            })
            .catch((error) => {
                var messageText = "Error reading Scandata Reviews: " + error;
                confirmAlert({
                    title: 'Error Retrieving Scandata Reviews',
                    message: messageText,
                    buttons: [
                        {
                            label: 'Continue',
                        }
                    ]
                });

            });
    }





    renderDetail() {

        if (this.state.selectedReportingGroup == null) {
            return "";
        }
        let AltriaRunLog = null;
        let AltriaOpRunLog = null;
        let AltriaSummaryLog = null;
        let RjrCircanaRunLog = null;
        let RjrCircanaOpRunLog = null;
        let RjrCircanaSummaryLog = null;
        let RjrRunLog = null;
        let RjrOpRunLog = null;
        let RjrSummaryLog = null;
        let AltriaBenchmarkRunLog = null;
        let AltriaBenchmarkOpRunLog = null;
        let AltriaBenchmarkSummaryLog = null;
        let AltriaActivityRunLog = null;
        let AltriaActivityOpRunLog = null;
        let AltriaActivitySummaryLog = null;

        //Altria Benchmark
        if (this.state.BenchmarkLogFileContent != null) {
            if (this.state.BenchmarkLogFileContent.runLogContent != null) {
                AltriaBenchmarkRunLog = this.state.BenchmarkLogFileContent.runLogContent;
                AltriaBenchmarkRunLog = AltriaBenchmarkRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.BenchmarkLogFileContent != null) {
            if (this.state.BenchmarkLogFileContent.opRunLogContent != null) {
                AltriaBenchmarkOpRunLog = this.state.BenchmarkLogFileContent.opRunLogContent;
                AltriaBenchmarkOpRunLog = AltriaBenchmarkOpRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.BenchmarkLogFileContent != null) {
            if (this.state.BenchmarkLogFileContent.summaryLogContent != null) {
                AltriaBenchmarkSummaryLog = this.state.BenchmarkLogFileContent.summaryLogContent;

            }

        }

        //Rjr Circana
        if (this.state.RJRCircanaLogFileContent != null) {
            if (this.state.RJRCircanaLogFileContent.runLogContent != null) {
                RjrCircanaRunLog = this.state.RJRCircanaLogFileContent.runLogContent;
                RjrCircanaRunLog = RjrCircanaRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.RJRCircanaLogFileContent != null) {
            if (this.state.RJRCircanaLogFileContent.opRunLogContent != null) {
                RjrCircanaOpRunLog = this.state.RJRCircanaLogFileContent.opRunLogContent;
                RjrCircanaOpRunLog = RjrCircanaOpRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.RJRCircanaLogFileContent != null) {
            if (this.state.RJRCircanaLogFileContent.summaryLogContent != null) {
                RjrCircanaSummaryLog = this.state.RJRCircanaLogFileContent.summaryLogContent;

            }

        }

        //Altria Activity
        if (this.state.ActivityLogFileContent != null) {
            if (this.state.ActivityLogFileContent.runLogContent != null) {
                AltriaActivityRunLog = this.state.ActivityLogFileContent.runLogContent;
                AltriaActivityRunLog = AltriaActivityRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.ActivityLogFileContent != null) {
            if (this.state.ActivityLogFileContent.opRunLogContent != null) {
                AltriaActivityOpRunLog = this.state.ActivityLogFileContent.opRunLogContent;
                AltriaActivityOpRunLog = AltriaActivityOpRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.ActivityLogFileContent != null) {
            if (this.state.ActivityLogFileContent.summaryLogContent != null) {
                AltriaActivitySummaryLog = this.state.ActivityLogFileContent.summaryLogContent;

            }

        }

        //Altria
        if (this.state.AltriaLogFileContent != null) {
            if (this.state.AltriaLogFileContent.runLogContent != null) {
                AltriaRunLog = this.state.AltriaLogFileContent.runLogContent;
                AltriaRunLog = AltriaRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.AltriaLogFileContent != null) {
            if (this.state.AltriaLogFileContent.opRunLogContent != null) {
                AltriaOpRunLog = this.state.AltriaLogFileContent.opRunLogContent;
                AltriaOpRunLog = AltriaOpRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.AltriaLogFileContent != null) {
            if (this.state.AltriaLogFileContent.summaryLogContent != null) {
                AltriaSummaryLog = this.state.AltriaLogFileContent.summaryLogContent;
            }

        }

        //RJR
        if (this.state.RJRLogFileContent != null) {
            if (this.state.RJRLogFileContent.runLogContent != null) {
                RjrRunLog = this.state.RJRLogFileContent.runLogContent;
                RjrRunLog = RjrRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.RJRLogFileContent != null) {
            if (this.state.RJRLogFileContent.opRunLogContent != null) {
                RjrOpRunLog = this.state.RJRLogFileContent.opRunLogContent;
                RjrOpRunLog = RjrOpRunLog.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />");
            }

        }

        if (this.state.RJRLogFileContent != null) {
            if (this.state.RJRLogFileContent.summaryLogContent != null) {
                RjrSummaryLog = this.state.RJRLogFileContent.summaryLogContent;

            }

        }
        return <div style={{ marginLeft: "20px" }}>
            <Tabs defaultTab="altria">
                <TabList>
                    <Tab tabFor="altria">Altria</Tab>
                    <Tab tabFor="rjr">RJR</Tab>
                    <Tab tabFor="rjr-circana">RJRCircana</Tab>
                    <Tab tabFor="altria-activity">Activity</Tab>
                    <Tab tabFor="altria-benchmark">Benchmark</Tab>
                </TabList>

                <TabPanel tabId="altria">
                    <br></br>
                    <h2>Altria - {this.state.selectedReportingGroup.scandata_group_name}</h2>
                    <br></br>
                    <CustomAccordion text={AltriaRunLog} title="Run Log"></CustomAccordion>
                    <CustomAccordion text={AltriaOpRunLog} title="OpRun Log"></CustomAccordion>
                    <CustomAccordion text={AltriaSummaryLog} title="Summary Log"></CustomAccordion>
                </TabPanel>
                <TabPanel tabId="rjr">
                    <br></br>
                    <h2>RJR - {this.state.selectedReportingGroup.scandata_group_name}</h2>
                    <br></br>
                    <CustomAccordion text={RjrRunLog} title="Run Log"></CustomAccordion>
                    <CustomAccordion text={RjrOpRunLog} title="OpRun Log"></CustomAccordion>
                    <CustomAccordion text={RjrSummaryLog} title="Summary Log"></CustomAccordion>
                </TabPanel>
                <TabPanel tabId="rjr-circana">
                    <br></br>
                    <h2>RJRCircana - {this.state.selectedReportingGroup.scandata_group_name}</h2>
                    <br></br>
                    <CustomAccordion text={RjrCircanaRunLog} title="Run Log"></CustomAccordion>
                    <CustomAccordion text={RjrCircanaOpRunLog} title="OpRun Log"></CustomAccordion>
                    <CustomAccordion text={RjrCircanaSummaryLog} title="Summary Log"></CustomAccordion>
                </TabPanel>
                <TabPanel tabId="altria-activity">
                    <br></br>
                    <h2>Activity - {this.state.selectedReportingGroup.scandata_group_name}</h2>
                    <br></br>
                    <CustomAccordion text={AltriaActivityRunLog} title="Run Log"></CustomAccordion>
                    <CustomAccordion text={AltriaActivityOpRunLog} title="OpRun Log"></CustomAccordion>
                    <CustomAccordion text={AltriaActivitySummaryLog} title="Summary Log"></CustomAccordion>
                </TabPanel>
                <TabPanel tabId="altria-benchmark">
                    <br></br>
                    <h2>Benchmark - {this.state.selectedReportingGroup.scandata_group_name}</h2>
                    <br></br>
                    <CustomAccordion text={AltriaBenchmarkRunLog} title="Run Log"></CustomAccordion>
                    <CustomAccordion text={AltriaBenchmarkOpRunLog} title="OpRun Log"></CustomAccordion>
                    <CustomAccordion text={AltriaBenchmarkSummaryLog} title="Summary Log"></CustomAccordion>
                </TabPanel>
            </Tabs>
        </div>
    }
    renderButton() {
        if (this.state.reviews !== null) {
            return <button style={{ marginLeft: "10px" }} onClick={this.handleButtonClick} className="btn btn-primary btn-block">Refresh</button>
        }
        else return "";
    }

    render() {
        let rb = this.renderButton();
        let bsTable = (this.state.reviews == null) ? ""
            :
            <div>
                <div style={{ maxHeight: "600px", maxWidth: "700px", overflowY: "scroll", float: "left" }}>
                    <BootstrapTable columns={ScandataReviewColumns.map(col => {
                        if (col.dataField === 'scandata_group_name') {
                            col.filter = textFilter({


                                getFilter: (filter) => { this.filter = filter },
                                onFilter: (filterValue) => { this.filterValue = filterValue }
                            })
                        }
                        return col

                    })} pagination={paginationFactory({ sizePerPage: 50 })} data={this.state.reviews} keyField='scandata_group_name' bootstrap4={true} striped={true} classes="small-table"
                        defaultSorted={[{ dataField: "scandata_group_name" }]}
                        filter={filterFactory()}
                        rowEvents={
                            {
                                onClick: (e, row, rowIndex) => {
                                    this.handleGroupSelect(e, row);
                                }
                            }
                        }
                        selectRow={selectRow}
                        headerClasses='tr-sticky' />
                </div>
            </div>
        let detailTable = this.renderDetail();
        return (<div onClick={(event) => {
            var calendarEls = document.getElementsByClassName('MuiDateCalendar-root')
            var clickedOutside = true;
            for (var i = 0; i < calendarEls.length; i++) {
                if (calendarEls[i].contains(event.target)) {
                    clickedOutside = false;
                    break;
                }
            }
            this.setState({ showCalendar: !clickedOutside })
        }}>
            <div id="blur" style={{ display: this.state.blurDisplay }}>
            </div>
            <div style={{ minWidth: "1400px" }}>
                <Container>
                    <Row>
                        <Col >
                            <br></br>
                            <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>Scandata Review  {rb}</span>
                            <br></br>
                            <div style={{ textAlign: "center", color: "red", fontSize: "large" }}>
                                {this.state.message}
                            </div>
                            <br></br>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Tooltip title="Previous Week Ending" placement="left">
                                    <NavigateBeforeIcon fontSize="large" fontWeight="bold" color="primary" onClick={this.handlePreviousWeekEnding} className="pointer"></NavigateBeforeIcon>
                                </Tooltip>
                                <Box>
                                    <CalendarMonthIcon className="pointer" onClick={
                                        (event) => {
                                            event.stopPropagation()
                                            this.setState(prevState => { return { ...prevState, showCalendar: !prevState.showCalendar } })
                                        }
                                    }></CalendarMonthIcon>
                                    {
                                        this.state.showCalendar ? (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateCalendar
                                                    sx={{
                                                        position: 'absolute',
                                                        backgroundColor: 'white',
                                                        border: '1px solid lightgrey',
                                                        zIndex: 1000
                                                    }}
                                                    value={this.state.selectedDate}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(newVal) => {
                                                        this.setState({ selectedDate: newVal })
                                                        const now = new Date()
                                                        const dayOfWeekNow = now.getDay()
                                                        const currentWeekEnding = new Date(now.getTime() - dayOfWeekNow * 24 * 3600 * 1000)
                                                        let dSelectedDate = new Date(newVal)
                                                        if (dSelectedDate.getDate() === 6)
                                                            dSelectedDate = new Date(dSelectedDate.getTime() + 24 * 3600 * 1000)
                                                        const dateDiff = dSelectedDate.getTime() - currentWeekEnding.getTime()
                                                        const dateDiffInWeeks = Math.round(dateDiff / (24 * 3600 * 1000 * 7))

                                                        if (dateDiffInWeeks < 0 && dateDiffInWeeks !== parseInt(this.state.periodToAdd))
                                                            this.setState({ reviews: null, showCalendar: false, periodToAdd: dateDiffInWeeks, selectedReportingGroup: null }, () => {
                                                                this.getReviewData();
                                                            });
                                                    }}
                                                    shouldDisableDate={(date) => this.disableDate(date)}
                                                />
                                            </LocalizationProvider>
                                        ) : ''
                                    }

                                </Box>
                                <Tooltip title="Next Week Ending" placement="right">
                                    <NavigateNextIcon fontSize="large" fontWeight="bold" color="primary" onClick={this.handleNextWeekEnding} className="pointer" ></NavigateNextIcon>
                                </Tooltip>
                            </Box>


                            {bsTable}
                        </Col>
                        <Col>
                            <div style={{ float: "left", maxWidth: "550px" }}>
                                {detailTable}

                            </div>
                        </Col>
                    </Row>
                </Container>



            </div>
        </div>);
    }
}
export default ScandataReview;